import * as actionTypes from '../action.js';
import axios from '../../Axios/axios-details';
import qs from 'qs';



export const authStart = () =>{
    return {
        type:actionTypes.AUTH_START
    };
};

export const authSuccess = (token,canUpdateUser,canUpdateVehicle) =>{
    return {
        type:actionTypes.AUTH_SUCCESS,
        token:token,
      canUpdateUser:canUpdateUser,
      canUpdateVehicle:canUpdateVehicle
    }
}

export const authFailure = (error) =>{
    return {
        type:actionTypes.AUTH_FAILED,
        error:error
    }
}

export const logout=() =>{
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    return {
        type:actionTypes.INIT_LOGOUT
    }
}



export const auth = (userId,password) =>{
    //const localStorageService = LocalStorageService.getService();
    return dispatch => {
        dispatch(authStart());
        //console.log(userId,password);
        const authData={
            username:userId,
            password:password,
        }
        //console.log(authData);

        axios.post('/login', qs.stringify(authData) )
        .then(res => {
            // console.log(res)
            const { data } = res;
            // console.log(data);

            if(data.success) {
                localStorage.setItem("username",userId);
              localStorage.setItem("cuA",res.data.username);
              localStorage.setItem('access_token',data.access_token);
              localStorage.setItem('refresh_token',data.refresh_token);
              localStorage.setItem('cUU',res.data.canEditUsers);
              localStorage.setItem('cUV', res.data.canEditVehicles);
              dispatch(authSuccess(data.access_token,res.data.canEditUsers,res.data.canEditVehicles));
            } else {
              dispatch(authFailure("Invalid Credentials"));
            }

            }).catch(err =>{
            dispatch(authFailure("Invalid Credentials"));
            console.log(err);

        });
    };
};

export const setAuthRedirect= (path) =>{
    return{
        type:actionTypes.SET_AUTH_REDIRECT_PATH,
        path:path
    }
}

export const authCheckStatus= () =>{
    return dispatch =>{
        const token = localStorage.getItem('access_token');
        let canUpdateUser = localStorage.getItem('cUU');
        if(canUpdateUser!="true")
          canUpdateUser = false;
        let canUpdateVehicle = localStorage.getItem('cUV');
        if(canUpdateVehicle!="true")
          canUpdateVehicle=false;
        if(!token){
            dispatch(logout());
        }
        else{
            dispatch(authSuccess(token,canUpdateUser,canUpdateVehicle));
        }
    }
}

export  default{
    auth, logout, authCheckStatus
} ;
