import React, { useEffect } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import { useSelector,useDispatch } from 'react-redux';
import AllActions from './store/actions';
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/Layout/TheLayout.js'));

const Login = React.lazy(() => import('./containers/Auth/Login'));



const App = () => {

  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(AllActions.Auth.authCheckStatus());
  },[dispatch]);

  const token = useSelector((state) => state.auth.token);
  const canUpdateUser = false;
  const canUpdateVehicle = true;
  console.log(canUpdateVehicle);
  return (
    <>
    {
      token ?
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route path="/" name="Home" render={props => <TheLayout {...props} canUpdateUser={canUpdateUser} canUpdateVehicle={canUpdateVehicle}/>} />
          </Switch>
        </React.Suspense>
      </HashRouter>

      :
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>

            <Route path="/" name="Login" render={props => <Login {...props}/>} />

          </Switch>
        </React.Suspense>
      </HashRouter>
    }
    </>
  );

}

export default App;
